import classes from "./home.module.css";
import image1 from "../../assets/pexels-denys-gromov-4781948.jpg";
import image2 from "../../assets/pexels-marcus-herzberg-2086765.jpg";
import image3 from '../../assets/pexels-nextvoyage-1470502.jpg'
import ServiceCard from "../../components/ServiceCard/ServiceCard";
import { useState } from "react";
import { useEffect } from "react";
import axios from "../../api/axios";
import TopCities from "../../components/TopCities/TopCities";
import { Carousel } from "react-bootstrap";
import { Helmet } from "react-helmet-async";

const Home = () => {
  const [faqs, setFaqs] = useState([]);
  const [services, setServices] = useState([]);
  const [banners, setBanners] = useState([]);
  const serviceApi = 'api/service-list';
  const bannerApi = 'api/banners';
  const faqsApi = 'api/faq-list';

  useEffect(() => {
    const fetchBanners = async () => {
      const response = await axios.get(bannerApi)
      setBanners(response.data.data)
    }
    fetchBanners();
  }, [bannerApi]);

  useEffect(() => {
    const fetchServices = async () => {
      const response = await axios.get(serviceApi)
        setServices(response.data.data)
    }
    fetchServices();
  }, [serviceApi]);

  useEffect(() => {
    const fetchData = async() => {
      const response = await axios.get(faqsApi);

      if(response){
        // console.log(response.data)
        setFaqs(response.data.data)
      }
    }

    fetchData();
  }, [faqsApi])

  return (
    <>
    <Helmet>
      <title>Home | Ma Travel and Tourism</title>
      <meta name="description" content="Discover wonder and luxury with MA Travel & Tourism, serving Dubai, Abu Dhabi, and Ras Al Khaimah. Experience beauty, culture, and adventure!" />
      <link rel="canonical" href={`/`} />
    </Helmet>
      <section className={`${classes.heroSection}`}>
        <Carousel>
          {banners?.map(banner => (
            <Carousel.Item key={`image_${banner?.id}`}>
              <img className={classes.bannerCarouselImage} src={`${process.env.REACT_APP_API_BASE_URL}${banner?.image}`} alt={`${banner.title}`} />
              <div className="container">
                <div className="row">
                  <div className="col-12 col-lg-6"></div>
                  <div className={`${classes.heroContainer} col-12 col-lg-6`}>
                    <div className={classes.heroContent}>
                      <h2 className="primary-heading">
                      {banner.title}
                      </h2>
                      <p>
                      {banner.description}
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </Carousel.Item>
          ))}
        </Carousel> 
      </section>
      <section className={classes.section}>
        <div className="container">
          <div className="row">
            <div className="col-12 col-lg-6 align-self-center">
              <h1 className="accent-heading fs-6">MA Travel and Tourism: Your Passport to Arabian Dreams</h1>
              <p className="fs-5">
                Embark on a journey of wonder and luxury with MA Travel and Tourism, your premier destination company serving the captivating cities of Dubai, Abu Dhabi, and Ras Al Khaimah. At MA Travel and Tourism, we understand that travel is not just about reaching a destination; it's about experiencing a world of unparalleled beauty, culture, and adventure.
              </p>
            </div>
            <div className="col-12 col-lg-6">
              <div className={classes.imageContainer}>
                <img
                  className={`${classes.decorImages} ${classes.decorImages1}`}
                  src={image1}
                  alt="vehicle"
                />
                <img
                  className={`${classes.decorImages} ${classes.decorImages2}`}
                  src={image2}
                  alt="vehicle"
                />
                <img
                  className={`${classes.decorImages} ${classes.decorImages3}`}
                  src={image3}
                  alt="vehicle"
                />
              </div>
            </div>
          </div>
        </div>
      </section>
      <section>
        <TopCities />
      </section>
      <section className={classes.section}>
        <div className="container">
          <div className="row gap-3 gap-lg-0">
            <div className="col-12 col-lg-8 mb-3">
              <h2 className="primary-heading">
                Top Experiences in Dubai
              </h2>
              <p>Unveil culture, nature, thrills, and record-breaking encounters beyond imagination. Unleash your desires In Dubai!</p>
            </div>
          </div>
          <div className="mt-3 mt-lg-0">
            <div className=" grid grid-col-auto">
              {services.filter(service => service?.group?.title.toLowerCase() === "dubai" && service.popular === 1).map((service) => 
                ( <ServiceCard key={`service_${service.id}`} id={service.id} title={service.title} image={`${process.env.REACT_APP_API_BASE_URL}${service.images[0].image}`} />)
              )}
              {services.filter(service => service?.group?.title.toLowerCase() === "dubai" && service.popular === 1).length <= 0 && <div className="d-flex align-items-center justify-content-center"><h3 className="fs-5">No Services available yet!</h3></div>}
            </div>
          </div>
        </div>
      </section>
      <section className={classes.section}>
        <div className="container">
          <div className="row gap-3 gap-lg-0">
            <div className="col-12 col-lg-8 mb-3">
              <h2 className="primary-heading">
                Top Experiences in Abu Dhabi
              </h2>
              <p>Discover the best attractions and activities that the UAE's capital city has to offer.</p>
            </div>
          </div>
          <div className="mt-3 mt-lg-0">
            <div className="grid grid-col-auto">
              {services.filter(service => service?.group?.title.toLowerCase() === "abu dhabi" && service.popular === 1).map  ((service) => 
                  ( <ServiceCard key={`service_${service.id}`} id={service.id} title={service.title} image={`${process.env.REACT_APP_API_BASE_URL}${service.images[0].image}`} />)
                )}
              {services.filter(service => service?.group?.title.toLowerCase() === "abu dhabi" && service.popular === 1).length <= 0 && <div className="d-flex align-items-center justify-content-center"><h3 className="fs-5">No Services available yet!</h3></div>}
            
            </div>
          </div>
        </div>
      </section>
      <section className={classes.section}>
        <div className="container">
          <div className="row gap-3 gap-lg-0">
            <div className="col-12 col-lg-8 mb-3">
              <h2 className="primary-heading">
                Top Experiences in Ras Al Khaimah
              </h2>
              <p>Explore rich culture, pristine beaches, and unforgettable adventures in this captivating haven In Dubai!</p>
            </div>
          </div>
          <div className="mt-3 mt-lg-0">
            <div className="grid grid-col-auto">
              {services.filter(service => service?.group?.title.toLowerCase() === "ras al khaimah" && service.popular === 1).map((service) => 
                (<ServiceCard key={`service_${service.id}`} id={service.id} title={service.title} image={`${process.env.REACT_APP_API_BASE_URL}${service.images[0].image}`} />)
              )}
              {services.filter(service => service?.group?.title.toLowerCase() === "ras al khaimah" && service.popular === 1).length <= 0 && <div className="d-flex align-items-center justify-content-center"><h3 className="fs-5">No Services available yet!</h3></div>}
            </div>
          </div>
        </div>
      </section>
      <section className={classes.section}>
        <div className="container">
          <h2 className="text-center">FAQs</h2>
          <div className="row align-items-center justify-content-center mt-3">
            <div className="col-12 col-lg-8">
              <div className="accordion" id="accordionQuestions">
                {faqs.map(faq => (
                  <div key={`faqs_${faq.id}`} className="accordion-item">
                    <h2 className="accordion-header">
                      <button className="accordion-button" type="button" data-bs-toggle="collapse" data-bs-target={`#collapse_${faq.id}`} aria-expanded="false" aria-controls={`#collapse_${faq.id}`}>
                        {faq.question}
                      </button>
                    </h2>
                    <div id={`collapse_${faq.id}`} className="accordion-collapse collapse" data-bs-parent="#accordionQuestions">
                      <div className="accordion-body">
                        {faq.answer}
                      </div>
                    </div>
                  </div>
                ))}
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};
export default Home;
