import { Container } from "react-bootstrap";
import "./aboutus.css";
import image1 from "../../assets/pexels-denys-gromov-4781948.jpg";
import image2 from "../../assets/pexels-marcus-herzberg-2086765.jpg";
import image3 from '../../assets/pexels-nextvoyage-1470502.jpg'
import { Helmet } from "react-helmet-async";

const AboutUs = () => {
  return (
    <>
      <Helmet>
        <title>About us | Ma Travel and Tourism</title>
        <meta name="description" content="Explore MA Travel & Tourism: Your trusted partner for luxury travel in Dubai, Abu Dhabi, and Ras Al Khaimah. Unforgettable journeys await!" />      
        <link rel="canonical" href={`/about-us`} />

      </Helmet>
      <section className="py-4">
        <Container>
          <h2 className="about-page-title">About Us</h2>
          <div className="row">
            <div className="col-12 col-lg-6 align-self-center">
              <h3 className="accent-heading fs-5">MA Tours</h3>
              <h1 className="primary-heading">
                About MA <span className="text-primary">Travel</span> and <span className="text-primary">Tourism</span>
              </h1>
              <p className="fs-5">
              Welcome to MA Travel and Tourism, your gateway to unforgettable journeys across Dubai, Abu Dhabi, and Ras Al Khaimah. As a premier travel company rooted in Dubai, we curate exceptional experiences that reflect the essence of these vibrant destinations. Our expert team is dedicated to crafting seamless travel adventures, from exploring the iconic cityscapes to immersing in the rich cultures and breathtaking landscapes. Embark on a remarkable voyage with us and let MA Travel and Tourism redefine your travel aspirations. Your journey begins here.
              </p>
            </div>
            <div className="col-12 col-lg-6">
              <div className="imageContainer">
                <img
                  className={`decorImages decorImages1`}
                  src={image1}
                  alt=""
                />
                <img
                  className={`decorImages decorImages2`}
                  src={image2}
                  alt=""
                />
                <img
                  className={`decorImages decorImages3`}
                  src={image3}
                  alt=""
                />
              </div>
            </div>
          </div>
        </Container>
      </section>
      <section className="why-section">
        <Container>
          <div>
            <h2>Why Choose MA Travel and Tourism?</h2>
            <p>
            <span>Local Expertise:</span> Our team of seasoned travel professionals possesses in-depth knowledge of the UAE, ensuring you get the most authentic experiences.
            </p>

            <p>
            <span>Tailor-Made Itineraries:</span> We believe in personalized journeys, crafting each itinerary to match your unique preferences and interests.
            </p>
            <p>
            <span>Impeccable Service:</span> Our commitment to excellence ensures you receive top-notch service from the moment you land to the moment you depart.
            </p>

            <p>
            <span>Unforgettable Memories:</span> MA Travel and Tourism strives to create memories that last a lifetime, ensuring your trip to the UAE is filled with extraordinary moments.
            </p>
            <p>
            Embark on a voyage with MA Travel and Tourism, and let us transform your travel dreams into reality. We invite you to explore, discover, and fall in love with Dubai, Abu Dhabi, and Ras Al Khaimah, where adventure knows no bounds and luxury knows no limits. Your Arabian adventure awaits.
            </p>
          </div>
        </Container>
      </section>
    </>
  );
};
export default AboutUs;
