// import classes from './topCities.module.css'
import { useEffect, useState } from 'react';
import CityCard from '../CityCard/CityCard';
import axios from '../../api/axios';

const TopCities = () => {
  const [cities, setCities] = useState([]);
  const citiesApi = '/api/groups';

  useEffect(() => {
    const fetchCities = async () => {
      const response = await axios.get(citiesApi)
      setCities(response.data.data)
    }
    fetchCities();
  }, [citiesApi]);
  return (
    <div className='container'>
      <div className='my-5'>
        <div >
          <h2 className="primary-heading">
            Top Cities to Visit
          </h2>
          <p className='heading-description'>
            Embark on a captivating journey of uncovering hidden treasures and renowned landmarks in premier global destinations - your spirit of adventure beckons!
          </p>
        </div>
        <div className="mt-3 mt-lg-0">
          <div className="grid grid-col-auto">
            {cities.map(city => (
              <CityCard key={`city_${city.id}`} title={city.title} image={`${process.env.REACT_APP_API_BASE_URL}${city.image}`} id={city.id} />
            ))}
          </div>
        </div>
      </div>
    </div>
  )
}
export default TopCities