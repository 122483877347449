import { Container } from "react-bootstrap";
import { useParams } from "react-router-dom"
import classes from "./serviceByCity.module.css"
import { useEffect, useState } from "react";
import axios from "../../api/axios";
import ServiceCard from "../../components/ServiceCard/ServiceCard";
import { Helmet } from "react-helmet-async";
const ServiceByCity = () => {
  const {title, id} = useParams();
  const [city, setCity] = useState(null);
  const [services, setServices] = useState([]);
  const serviceAPI = "/api/services";
  const cityAPI = "/api/groups"

  useEffect(() => {
    const fetchServices = async () => {
      const response = await axios.get(`${serviceAPI}?group_id=${id}`)
        setServices(response.data.data)
    }
    fetchServices();
  }, [serviceAPI, id]);

  useEffect(() => {
    const fetchCity = async () => {
      const response = await axios.get(`${cityAPI}/${id}`)
        setCity(response.data.data)
    }
    fetchCity();
  }, [cityAPI, id])

  return (
    <>
    <Helmet>
      <title>{`${city?.title}'s Tours`} | Ma Travel and Tourism</title>
      <meta name="description" content={`Explore our ${city?.title}'s city specific tours. Dive into unique experiences tailored to each vibrant destination`} />
      <link rel="canonical" href={`${city?.title}/${id}`} />
    </Helmet>
      <section className={classes.citySection}>
        <Container>
          <h1 className={classes.cityTitle}>{`Top ${title} Tours`}</h1>
          <div className="card p-3">
            <h4>Best Tours And Activities In {city?.title}</h4>
            <p>{city?.description}</p>
          </div>
          <div className="row justify-content-center mt-3">
            <div>
            <h4>Things to do in {city?.title}:</h4>
            </div>
            <div className="grid grid-col-auto my-3">
              {services.map((service) => (
                <ServiceCard key={`service_${service.id}`} id={service.id} title={service.title} image={`${process.env.REACT_APP_API_BASE_URL}${service?.images[0]?.image}`} />
              ))}
            </div>
          </div>
        </Container>
      </section>
    </>
  )
}
export default ServiceByCity